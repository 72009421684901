import { useEffect, useState } from 'react'
import { useMemoizedContentGetter } from 'utils/contentful'
import { MediaImageStandard, Typography } from 'components/Contentful'
import Section, { SECTION_VARIANTS } from 'components/Section'
import styles from './MoloHero.module.scss'
import { Grid } from '@achieve/sunbeam'
import { BREAKPOINTS } from 'utils/mui'
import { AchieveLink } from 'components/AchieveLink'
import useTheme from 'hooks/useTheme'
import useIsSsr from 'hooks/useIsSsr'
import { get as _get } from 'lodash-es'
import { actualDevice, DEVICES } from 'utils/shared'
import { rewriteAssetUrl } from 'utils/conversions/urls'
import classNames from 'classnames'
import useSsrViewportCheck from 'hooks/useSsrViewportCheck/useSsrViewportCheck'

function MoloHero({ content }) {
  const isSsr = useIsSsr()
  const isMobile = useSsrViewportCheck(content?.isMobileUA, BREAKPOINTS.md)
  const theme = useTheme()
  const [device, setDevice] = useState(null)

  useEffect(() => {
    if (!isSsr) {
      // check If current browser is a safari browser IOS
      setDevice(actualDevice(navigator.userAgent))
    }
  }, [isSsr])

  const {
    button_apple: buttonApple,
    button_google: buttonGoogle,
    copy,
    eyebrow,
    config,
    heroImage,
    image_apple: imageApple,
    image_google: imageGoogle,
    title,
    heroVideo,
  } = useMemoizedContentGetter(content, [
    'button_apple',
    'button_google',
    'copy',
    'eyebrow',
    'config',
    'heroImage',
    'heroVideo',
    'image_apple',
    'image_google',
    'title',
  ])

  const heroVideoUrl = rewriteAssetUrl(_get(heroVideo, 'mediaContent.fields.file.url', ''))
  const variation = config?.jsonContent?.variation || 'default'
  const heroImageContent = _get(heroImage, 'mediaContent', null)
  const imageAlt = _get(heroImage, 'mediaAltText', null)

  return (
    <Section
      className={classNames(
        styles['section'],
        ...[variation == 'GoodApp' ? styles['section-good-app'] : null]
      )}
      childContainerClassName={styles['section-inner']}
      data-testid="molo-hero-section"
      data-narrow={true}
      variant={
        variation == 'GoodApp'
          ? SECTION_VARIANTS.bgShadowPrimaryHighlightWhiteBlue
          : SECTION_VARIANTS.bgShadowPrimaryHighlightBlue
      }
      contain
    >
      <Grid
        className={styles['content-container']}
        container
        justifyContent="center"
        direction={isMobile ? 'column' : 'row'}
      >
        <Grid className={styles['copy']} item xs={12} lg={variation == 'GoodApp' ? 5 : 6}>
          {eyebrow && (
            <Typography
              content={eyebrow?.textContent}
              variant={isMobile ? 'displayXS30' : 'displayS10'}
              fontWeight={isMobile ? 'bold' : 'medium'}
              component="h1"
              data-testid="molo-hero-eyebrow"
            />
          )}
          {title && (
            <Typography
              content={title?.textContent}
              color={theme?.sb?.colors?.primary?.achieveBlue}
              variant={
                isMobile ? (variation == 'GoodApp' ? 'displayM30' : 'displayM10') : 'displayL10'
              }
              fontWeight="bold"
              component="h2"
              data-testid="molo-hero-title"
            />
          )}
          {copy && (
            <Typography
              content={copy?.textContent}
              variant={isMobile ? 'displayXS30' : 'displayS10'}
              fontWeight="normal"
              component="p"
              data-testid="molo-hero-copy"
            />
          )}

          <Grid className={styles['buttons']} container>
            {(device === DEVICES.desktop || device === DEVICES.ios) && imageApple && (
              <AchieveLink
                href={buttonApple?.linkHref}
                noLink
                track={{
                  click_id: imageApple?.mediaAltText || 'native-download-apple-en',
                  click_text: `Achieve-Web | ${
                    imageApple?.mediaAltText || 'native-download-apple-en'
                  }`,
                  click_type: 'Button Click',
                  click_position: 1,
                  event_type: 'button_click',
                  nav_link_section: 'Hero',
                  track_event: 'app_download_click',
                }}
                data-testid="molo-hero-app-store-btn"
              >
                <div className={styles['button-apple']}>
                  <MediaImageStandard content={imageApple?.mediaContent} />
                </div>
              </AchieveLink>
            )}
            {(device === DEVICES.desktop || device === DEVICES.android) && imageGoogle && (
              <AchieveLink
                href={buttonGoogle?.linkHref}
                noLink
                track={{
                  click_id: imageGoogle?.mediaAltText || 'native-download-google-en',
                  click_text: `Achieve-Web | ${
                    imageGoogle?.mediaAltText || 'native-download-google-en'
                  }`,
                  click_type: 'Button Click',
                  click_position: 2,
                  event_type: 'button_click',
                  nav_link_section: 'Hero',
                  track_event: 'app_download_click',
                }}
                data-testid="molo-hero-google-play-btn"
              >
                <div className={styles['button-google']}>
                  <MediaImageStandard content={imageGoogle?.mediaContent} />
                </div>
              </AchieveLink>
            )}
          </Grid>
        </Grid>
        {heroImageContent && (
          <Grid
            className={styles['hero-image-grid-item']}
            item
            xs={12}
            lg={variation == 'GoodApp' ? 7 : 6}
          >
            <HeroImage imageContent={heroImageContent} videoUrl={heroVideoUrl} alt={imageAlt} />
          </Grid>
        )}
      </Grid>
    </Section>
  )
}

function HeroImage({ imageContent, videoUrl = '', alt }) {
  const [loaded, setLoaded] = useState(false)

  return (
    <div className={styles['hero-image-container']}>
      {videoUrl && loaded && (
        <div className={styles['hero-video-container']}>
          <video
            autoPlay
            muted
            playsInline
            className={styles['video']}
            poster={imageContent ? imageContent?.fields?.file?.url.replace('//', '') : null}
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
        </div>
      )}
      {imageContent && (
        <MediaImageStandard
          content={imageContent}
          alt={alt}
          onLoadingComplete={() => setLoaded(true)}
        />
      )}
    </div>
  )
}
export { MoloHero }
export default MoloHero
