import useIsSsr from 'hooks/useIsSsr/useIsSsr'
import { BREAKPOINTS, useViewportLargerThan, useViewportSmallerThan } from 'utils/mui'
/**
A custom hook that checks whether the viewport matches the given breakpoint
and handles server-side rendering (SSR).
@param {boolean} [defaultValue=true] - The default value to return when SSR is true. Defaults to true.
@param {string} [breakpoint='lg'] - The breakpoint to check against. Defaults to 'lg'.
@param {boolean} [larger=false] - The strategy to use when checking against the breakpoint. Can be either false for 'smaller' or true for 'larger'. Defaults to 'smaller'.
@returns {boolean} - Returns true if the viewport matches the given breakpoint or the default value when SSR is true.
*/
const useSsrViewportCheck = (defaultValue = true, breakpoint = BREAKPOINTS.lg, larger = false) => {
  const isSmaller = useViewportSmallerThan(breakpoint)
  const isLarger = useViewportLargerThan(breakpoint)
  const isSsr = useIsSsr()
  const viewPort = !larger ? isSmaller : isLarger
  return isSsr ? defaultValue : viewPort
}

export default useSsrViewportCheck
