import classNames from 'classnames'
import { Typography } from 'components/Contentful'
import styles from './Eyebrow.module.scss'

function Eyebrow({ className, ...props }) {
  return (
    <Typography
      className={classNames(styles['eyebrow'], className)}
      data-variant={props.black ? 'black' : 'default'}
      {...props}
    />
  )
}

export { Eyebrow }
