import styles from 'components/Cards/CardRecommended/CardRecommended.module.scss'
import { Typography, MediaImage, LinkButton } from 'components/Contentful'
import { Paper, Typography as SunbeamTypography } from '@achieve/sunbeam'
import { BLOCKS } from '@contentful/rich-text-types'
import { isEmpty as _isEmpty } from 'lodash-es'
import { DollarSign, User, Watch } from 'react-feather'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'

export const CardRecommended = ({
  content,
  recommended = false,
  orderLarge,
  firstSelection,
  secondSelection,
  order,
}) => {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  if (_isEmpty(content)) return null

  const { cardTitleText, cardText, cardLink, secondaryCardLink, cardImage, cardJson } =
    content?.fields || {}

  // Use the main cardLink if the card is recommended or if there is no secondaryCardLInk
  const displayLink = recommended || !secondaryCardLink ? cardLink : secondaryCardLink

  const cardButtonText = displayLink?.fields?.linkText?.content[0]?.content[0].value || ''
  const cardTitleString = cardTitleText?.content[0]?.content[0]?.value
  const displayLinkString = displayLink?.fields?.linkText?.content[0]?.content[0]?.value
  const { recommendedItems } = cardJson || {}

  return (
    <Paper
      elevation={isMobile ? 0 : 10}
      className={`${styles['card']} ${recommended ? styles['card-recommended'] : ''}`}
      data-testid={`card-recommended-${cardTitleString}`}
      data-order-large={orderLarge}
    >
      {recommended && (
        <Typography variant="bodyS20" content="Top Choice" className={styles['card-top-choice']} />
      )}
      {cardImage && (
        <div className={styles['card-image']}>
          <MediaImage
            content={cardImage}
            width={68}
            height={68}
            layout="fixed"
            data-testid={`card-recommended-image-${cardTitleString}`}
          />
        </div>
      )}
      {cardTitleText && (
        <Typography
          content={cardTitleText}
          variantOverride={{ [BLOCKS.HEADING_3]: 'displayS20' }}
          className={styles['card-title']}
          fontWeight="medium"
          data-testid={`card-recommended-title-${cardTitleString}`}
        />
      )}
      <div className={styles['card-copy-container']}>
        {recommended && recommendedItems && (
          <div className={styles['card-recommended-items']}>
            {recommendedItems.map(({ text, icon }, i) => (
              <div className={styles['card-recommended-item']} key={i}>
                {getIconByIconName(icon)}
                <SunbeamTypography variant="displayXS20" fontWeight="medium" component="span">
                  {text}
                </SunbeamTypography>
              </div>
            ))}
          </div>
        )}
        {cardText && (
          <Typography
            content={cardText}
            variantOverride={{ [BLOCKS.PARAGRAPH]: 'bodyS30' }}
            className={styles['card-subtitle']}
            fontWeight="regular"
            data-testid={`card-recommended-text-${cardTitleString}`}
          />
        )}
      </div>
      {displayLink && (
        <LinkButton
          track={{
            list_name: 'Product Page More Resources',
            click_position: order,
            click_id: displayLinkString,
            click_text: displayLinkString,
            mad_lib_first_selection: firstSelection,
            mad_lib_second_selection: secondSelection,
            mad_lib_card_title: cardTitleString,
            mad_lib_card_recomended: recommended,
          }}
          content={displayLink}
          typographicOptions={{
            variantOverride: { [BLOCKS.PARAGRAPH]: 'displayS10' },
            fontWeight: 'bold',
            'data-testid': `card-recommended-button-text-${cardTitleString}`,
          }}
          color="primary"
          className={styles['button']}
          variant={recommended ? 'contained' : 'outlined'}
          data-testid={`card-recommended-button-${cardButtonText}`}
        />
      )}
    </Paper>
  )
}

// Note - This hack isn't ideal. There should be a nice way to reference icons by name from Contentful.
const getIconByIconName = (iconName) => {
  switch (iconName) {
    case 'DollarSign':
      return <DollarSign className={styles['recommended-item-icon']} />
    case 'Watch':
      return <Watch className={styles['recommended-item-icon']} />
    case 'User':
      return <User className={styles['recommended-item-icon']} />
    default:
      return
  }
}
