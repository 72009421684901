import { useRef } from 'react'
import { useMemoizedContentGetter } from 'utils/contentful'
import { LinkButton, Typography } from 'components/Contentful'
import Section from 'components/Section'
import styles from './HomeHeroV2.module.scss'
import { Grid, Box, Link } from '@achieve/sunbeam'
import classNames from 'classnames'
import { ArrowRight } from 'react-feather'
import { Eyebrow } from 'components/Eyebrow'
import { BLOCKS } from '@contentful/rich-text-types'
import { IconListItem } from 'components/IconListItem'
import useTheme from 'hooks/useTheme'
import { HandwrittenText } from 'components/HandwrittenText'
import useSsrViewportCheck from 'hooks/useSsrViewportCheck/useSsrViewportCheck'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { unslug } from 'utils/routes'
import { useRouter } from 'next/router'

/*
Home hero configs
{
  "color": "white",
  "contentBackground": "blue",
  "backgroundObjectPosition": "right",
  "backgroundObjectPositionMobile": "top",
  "heroVariant": "home",
}
*/

/*
Debt consolidation hero configs
{
  "backgroundObjectPosition": "right bottom",
  "backgroundObjectPositionMobile": "center",
  "color": "white",
  "contentBackground": "blue",
  "heroVariant": "debt-consolidation",
  "textAlign": {
      "mobile": "center",
      "desktop": "center"
  }
}
 */

function HomeHeroV2({ content }) {
  const {
    background,
    backgroundConfigJson: { jsonContent },
    button,
    eyebrow,
    handwrittenImg,
    iconListItems,
    link,
    subTitle,
    title,
  } = useMemoizedContentGetter(content, [
    'background',
    'backgroundConfigJson',
    'button',
    'eyebrow',
    'handwrittenImg',
    'iconListItems',
    'link',
    'subTitle',
    'title',
  ])

  return (
    <HomeHeroV2Module
      background={background}
      backgroundImageAlt={background?.mediaAltText}
      isMobileUA={content?.isMobileUA}
      eyebrow={eyebrow}
      title={title}
      subTitle={subTitle}
      link={link}
      button={button}
      jsonConfig={jsonContent}
      handwrittenImg={handwrittenImg}
      iconListItems={iconListItems}
    />
  )
}

function HomeHeroV2Module({
  background,
  eyebrow,
  title,
  subTitle,
  link,
  button,
  jsonConfig,
  isMobileUA,
  handwrittenImg,
  iconListItems,
}) {
  /// Get all the config values from the jsonConfig
  const {
    heroVariant,
    mobilePadding = 'bottom',
    backgroundObjectPosition = 'right',
    backgroundObjectPositionMobile = 'bottom',
    titleVariantMobile = 'displayM10',
    titleVariant = 'displayL10',
    subTitleVariantMobile = 'displayS10',
    subTitleVariant = 'displayS10',
    eyebrowVariantMobile = 'displayXS30',
    eyebrowVariant = 'displayXS30',
    showPattern = false,
    contentBackground,
    textAlign,
  } = jsonConfig
  const backgroundConfig = jsonConfig?.background ?? 'bgDefault'
  const isMobileGeneral = useSsrViewportCheck(isMobileUA, BREAKPOINTS.xl)
  const isMobileDebt = useSsrViewportCheck(isMobileUA, BREAKPOINTS.lg)
  const isMobile = heroVariant == 'debt-consolidation' ? isMobileDebt : isMobileGeneral
  const isXS = useViewportSmallerThan(BREAKPOINTS.md)
  const isMD = useViewportSmallerThan(BREAKPOINTS.lg)
  const isL = useViewportSmallerThan(BREAKPOINTS.xl)
  const router = useRouter()
  let finalBackground = background?.mediaContent
  if (isXS && background?.mobileMediaContent) {
    finalBackground = background?.mobileMediaContent
  }
  if (isMD && background?.middleMediaContent && !isXS) {
    finalBackground = background?.middleMediaContent
  }
  const textContainerRef = useRef(null)
  const theme = useTheme()

  let contentDirection = 'row'
  let contentDirectionMobile = 'column'

  if (backgroundObjectPosition === 'left') {
    contentDirection = 'row-reverse'
  }

  if (backgroundObjectPositionMobile === 'top') {
    contentDirectionMobile = 'column-reverse'
  }
  // define variant for text based on mobile or desktop and json file
  const isMobileText = heroVariant == 'debt-consolidation' ? isL : isMobile
  const currentTitleVariant = isMobileText ? titleVariantMobile : titleVariant
  const currentSubTitleVariant = isMobileText ? subTitleVariantMobile : subTitleVariant
  const currentEyebrowVariant = isMobileText ? eyebrowVariantMobile : eyebrowVariant

  return (
    <>
      <Section
        className={styles['section']}
        data-testid="closer-fullbg-section"
        data-narrow={true}
        data-hero-variant={heroVariant}
        backgroundImageContent={finalBackground}
        backgroundPriority={true}
        backgroundImageAlt={background?.mediaAltText}
        showPattern={showPattern}
        backgroundObjectPosition={
          isMobile ? backgroundObjectPositionMobile : backgroundObjectPosition
        }
        variant={backgroundConfig}
      >
        <Box
          className={classNames(styles['fullbg-content'], styles[mobilePadding])}
          component="div"
        >
          <Grid
            container
            direction={isMobile ? contentDirectionMobile : contentDirection}
            alignItems="center"
            data-testid="closer-fullbg-outer-grid-container"
          >
            {/* The content grid item */}
            <Grid
              item
              className={styles['grid-item-text']}
              data-testid="closer-fullbg-grid-item-text"
              container
            >
              <div
                className={styles['text-container']}
                ref={textContainerRef}
                style={{
                  textAlign: isMobile
                    ? textAlign?.mobile ?? 'center'
                    : textAlign?.desktop ?? 'left',
                }}
                data-container-color={contentBackground}
                data-testid="closer-fullbg-text-container"
              >
                {eyebrow && (
                  <Eyebrow
                    content={eyebrow?.textContent}
                    variant={currentEyebrowVariant}
                    fontWeight="bold"
                    component="h1"
                    className={classNames(
                      styles['eyebrow'],
                      {
                        [styles[`font-${eyebrow?.fontColor.toLowerCase()}`]]: Boolean(
                          eyebrow?.fontColor
                        ),
                      },
                      { [styles[`font-align-${eyebrow?.position}`]]: Boolean(eyebrow?.position) }
                    )}
                  />
                )}
                {title && (
                  <Typography
                    content={title?.textContent}
                    variant={currentTitleVariant}
                    fontWeight="bold"
                    className={classNames(
                      styles['title'],
                      styles[`font-${title?.fontColor.toLowerCase()}`]
                    )}
                  />
                )}
                {subTitle && (
                  <Typography
                    content={subTitle?.textContent}
                    variant={currentSubTitleVariant}
                    className={classNames(
                      styles['sub-title'],
                      styles[`font-${subTitle?.fontColor.toLowerCase()}`]
                    )}
                  />
                )}
                {iconListItems?.gridItems?.length > 0 && (
                  <div className={styles['icon-container']}>
                    <ul>
                      {iconListItems.gridItems.map((icon, index) => {
                        return (
                          <IconListItem
                            iconColor={icon.fields.iconColor}
                            iconName={icon.fields.iconName}
                            iconSize={isMobile ? 20 : 30}
                            itemText={icon.fields.description}
                            key={`icon-list-item-${index}`}
                            testIdPrefix="home-hero"
                            textColor={theme?.sb?.colors?.neutral?.white}
                            variant={isMobile ? 'displayXS30' : 'displayS20'}
                          />
                        )
                      })}
                    </ul>
                  </div>
                )}
                {link && (
                  <Link
                    underline="hover"
                    fontWeight="bold"
                    variant="displayS10"
                    href={link?.linkHref}
                  >
                    <Typography
                      content={link?.linkText}
                      fontWeight="bold"
                      className={styles['text-link']}
                      variant="displayS10"
                    />{' '}
                    <ArrowRight className={styles['svg-link']} />
                  </Link>
                )}
                {button && (
                  <LinkButton
                    track={{
                      list_name: 'Home Hero CTA click',
                      nav_link_section: `${
                        router.asPath === '/' ? 'Home' : unslug(router.asPath).slice(1)
                      } Hero`,
                      click_id: button?.linkText?.content[0]?.content[0]?.value,
                      click_text: button?.linkText?.content[0]?.content[0]?.value,
                    }}
                    typographicOptions={{
                      variantOverride: { [BLOCKS.PARAGRAPH]: 'displayS10' },
                      fontWeight: 'bold',
                    }}
                    variant="contained"
                    color="secondary"
                    content={button}
                    className={styles['cta-button']}
                  />
                )}
              </div>

              {handwrittenImg && (
                <div
                  className={styles['handwritten-text-container']}
                  data-hero-variant={heroVariant}
                >
                  <div className={styles['handwritten-text']} data-hero-variant={heroVariant}>
                    <HandwrittenText
                      alt={handwrittenImg?.mediaAltText}
                      content={handwrittenImg?.mediaContent}
                    />
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>
      </Section>
      {/* This is a spacer section that is required between the mobile HomeHeroV2 and the following
      section on the page. This is because the blue box is translated by 50% of its own height and
      the next section (which is unaware or the translation) needs to be offset to accommodate. The
      one gotcha is that the color of the _next_ section needs to be provided to match. */}
      <Section
        className={styles['mobile-spacer']}
        style={{
          // Use padding to match the offset of the floating textbox (50% of the textbox height).
          // Default to sane value like 200px while waiting for js to be loaded.
          paddingTop: textContainerRef?.current?.offsetHeight
            ? textContainerRef?.current?.offsetHeight - (isXS ? 130 : 47)
            : 400,
        }}
      />
    </>
  )
}

export { HomeHeroV2, HomeHeroV2Module }
export default HomeHeroV2
