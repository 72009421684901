import styles from 'components/IconListItem/IconListItem.module.scss'
import { BLOCKS } from '@contentful/rich-text-types'
import { Typography } from 'components/Contentful'
import {
  Users,
  Calendar,
  Percent,
  DollarSign,
  ArrowDown,
  Smile,
  Key,
  Scissors,
  Zap,
  BarChart,
} from 'react-feather'
import { Grid } from '@achieve/sunbeam'
import classNames from 'classnames'

const DEFAULT_ICON_SIZE = 15

const ICON_LIST = {
  Users,
  Calendar,
  Percent,
  DollarSign,
  ArrowDown,
  Smile,
  Key,
  Scissors,
  Zap,
  Rocket: BarChart,
}

function IconListItem({
  className,
  classNameIcon,
  classNameText,
  iconColor,
  iconName,
  iconSize = DEFAULT_ICON_SIZE,
  itemText,
  textColor,
  testIdPrefix = 'default',
  variant,
}) {
  const IconComponent = ICON_LIST[iconName]
  let textProps = {
    content: itemText,
    variantOverride: { [BLOCKS.PARAGRAPH]: 'displayXS30' },
    fontWeight: 'regular',
  }

  if (textColor) {
    textProps.color = textColor
  }

  return (
    <li className={classNames(styles['icon-list-item'], { [className]: Boolean(className) })}>
      <Grid className={styles['icon-item']} container direction="row">
        {IconComponent && (
          <IconComponent
            className={classNames(styles['icon-item-svg'], {
              [classNameIcon]: Boolean(classNameIcon),
            })}
            color={iconColor}
            width={iconSize}
            height={iconSize}
          />
        )}
        <div>
          <Typography
            {...textProps}
            data-testid={`${testIdPrefix}-icon-list-item`}
            variant={variant}
            className={classNameText}
          />
        </div>
      </Grid>
    </li>
  )
}

export { ICON_LIST, IconListItem }
