import styles from './CardPhone.module.scss'
import { Box } from '@achieve/sunbeam'
import { Typography } from 'components/Contentful'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'

function CardPhone({ content, breakline }) {
  const { cardTitleText, cardText } = content?.fields || {}
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  return (
    <Box
      variant="none"
      data-testid="card-container"
      className={[styles['card'], breakline && styles['breakline']]}
    >
      <Box className={styles['card-info']}>
        {cardTitleText && (
          <Typography
            data-testid="card-title"
            variant={'displayS20'}
            fontWeight="medium"
            className={styles['card-text-title']}
            content={cardTitleText}
          />
        )}

        {cardText && (
          <Typography
            data-testid="card-subtitle"
            className={styles['card-text-subtitle']}
            component="span"
            variant={isMobile ? 'displayM10' : 'displayS20'}
            fontWeight={isMobile ? 'regular' : 'medium'}
            content={cardText}
          />
        )}
      </Box>
    </Box>
  )
}

export { CardPhone }
