import styles from './ValueProps.module.scss'
import { Grid, Box } from '@achieve/sunbeam'
import Section from 'components/Section'
import { CardValueProp } from 'components/Cards'
import { TitleDivider } from 'components/TitleDivider'
import { BLOCKS } from '@contentful/rich-text-types'
import { LinkButton, Typography, MediaImage } from 'components/Contentful'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { useMemoizedContentGetter } from 'utils/contentful'
import classNames from 'classnames'
import useTheme from 'hooks/useTheme'
import { Carousel } from 'components/Carousels'

function Card({
  item: {
    fields: { cardImage, cardTitleText, cardText },
  },
  priority,
}) {
  // we are using anything smaller than lg as our mobile for this one
  // for now though, we will make a medium screen resolution later
  // *** UPDATE 7/8/22 ***
  // The lg breakpoint was changed (now 1024px) and xl (1280) is closer to the old lg (1200).
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const [quote] = cardText?.content ?? []
  return (
    <Grid
      container
      direction={{ xs: 'column-reverse' }}
      justifyContent={{ xs: 'flex-end' }}
      alignItems={{ xs: 'left' }}
    >
      <Grid item xs={6} className={styles['value-card-grid-carousel']}>
        <div className={styles['image-container']}>
          <MediaImage
            layout={'fixed'}
            content={cardImage}
            priority={priority}
            width={60}
            height={60}
          />
        </div>
        <Typography
          className={styles['carousel-item-title']}
          content={cardTitleText}
          fontWeight="bold"
          variant={isMobile ? 'displayS30' : 'displayM20'}
        />
        <Typography
          className={styles['carousel-item-quote']}
          content={quote}
          fontWeight="regular"
          variant="bodyS30"
        />
      </Grid>
    </Grid>
  )
}

function ValueProps({ content }) {
  const idContent = content?.sys?.id
  const {
    title,
    eyebrow,
    subtitle,
    grid,
    theme,
    linkButton,
    arrow,
    handwritten,
    backgroundConfigJson,
  } = useMemoizedContentGetter(content, [
    'title',
    'eyebrow',
    'subtitle',
    'grid',
    'theme',
    'linkButton',
    'arrow',
    'handwritten',
    'backgroundConfigJson',
  ])
  const jsonContent = backgroundConfigJson?.jsonContent || {}
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)

  const fieldName = content.fields.fieldName

  const achieveTheme = useTheme()
  const backgroundColor = theme?.theme[0]
    ? achieveTheme?.sb?.colors?.primary?.highlightBlue
    : achieveTheme?.sb?.colors?.neutral?.white
  const variant = {
    title: isMobile ? 'displayS20' : 'displayM20',
    titleFontWeight: jsonContent?.titleFontWeight ?? 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: jsonContent?.subtitleFontWeight ?? 'regular',
  }

  const breakline = ['aboutUsCulture', 'valueSeparatorProps'].includes(fieldName)
  const containerGrid = !['aboutUsCulture', 'partnersValueProps'].includes(fieldName)

  const itemsByrow = jsonContent?.itemsByrow || 3
  const carouselOnMobile = jsonContent?.carouselOnMobile || false
  const dividerOnMobile = jsonContent?.dividerOnMobile || false
  const marginOnMobile = jsonContent?.marginOnMobile ?? true
  const dividerToggle =
    jsonContent?.dividerToggle || jsonContent?.dividerToggle == false
      ? jsonContent?.dividerToggle
      : true
  const buttonColor = jsonContent?.buttonColor
  const cardStyle = jsonContent?.cardStyle

  const gridItems = (dividerOnMobile = false) => {
    return (
      <>
        {grid?.gridItems.map((item, idx) => {
          const card = (
            <Grid
              item
              lg={3.5}
              className={classNames(
                (idx + 1) % itemsByrow == 0 || !dividerToggle ? '' : styles['grid-item'],
                idx + 1 > itemsByrow && styles['spacing-grid-item']
              )}
              key={`card-${idx}`}
            >
              <Box
                data-testid={`value-prop-grid-${idContent}-card-${idx}`}
                className={styles['value-prop-grid-card']}
              >
                <CardValueProp
                  cardStyle={cardStyle}
                  content={item}
                  isMobile={isMobile}
                  breakline={breakline}
                  dividerOnMobile={dividerOnMobile}
                  pos={idx}
                  className={styles['card-container']}
                />
              </Box>
            </Grid>
          )

          return card
        })}
      </>
    )
  }

  return (
    <Section
      backgroundColor={!jsonContent?.background ? backgroundColor : null}
      className={classNames(styles['section'], { [styles['breakline']]: breakline })}
      variant={jsonContent?.background ?? 'bgDefault'}
      contain={isMobile ? marginOnMobile : true}
    >
      <Grid
        container
        justifyContent="center"
        data-testid={`value-prop-grid-container-${idContent}`}
        className={styles['container-grid']}
        data-no-margin={marginOnMobile}
      >
        <TitleDivider
          data-testid={`value-prop-title-divider-${idContent}`}
          eyebrow={eyebrow}
          eyebrowBlack
          eyebrowClassName={styles['eyebrow']}
          className={styles['title-divider-wrapper']}
          title={title}
          subtitle={subtitle}
          isMobile={isMobile}
          variants={variant}
          disableTitleTag={true}
          disableSubtitleTag={true}
        />

        {isMobile && carouselOnMobile ? (
          //only on mobile and carouselOnMobile set to true
          <Carousel
            className={styles['flat-carousel-wrapper']}
            carouselItems={grid?.gridItems}
            itemComponent={Card}
            variant="preview-small-xs"
            idPrefix="value-props-carousel"
            withIndicators
            classNameIndicators={styles['indicators']}
            classNameActiveDot={styles['active-indicator']}
          />
        ) : containerGrid ? (
          <Grid
            container
            data-testid={`value-prop-grid-card-container-${idContent}`}
            className={classNames(styles['card-grid'], { [styles['breakline']]: breakline })}
          >
            {gridItems(dividerOnMobile)}
          </Grid>
        ) : (
          // only for aboutUs culture
          <Grid
            item
            sm={10}
            md={8}
            lg={12}
            data-testid={`value-prop-grid-card-container-${idContent}`}
            className={classNames(styles[isMobile ? 'card-grid-column' : 'card-grid'], {
              [styles['breakline']]: breakline,
            })}
          >
            {gridItems()}
          </Grid>
        )}
        {linkButton && (
          <div className={styles['button-wrapper']}>
            <LinkButton
              className={styles['button']}
              content={linkButton}
              typographicOptions={{
                variantOverride: { [BLOCKS.PARAGRAPH]: 'displayS10' },
                fontWeight: 'bold',
                'data-testid': `value-prop-button-text-${idContent}`,
                className: styles['typography'],
              }}
              color={buttonColor ? buttonColor : 'primary'}
              data-testid={`value-prop-button-${idContent}`}
              track={{
                list_name: 'Personalized Value Props CTA',
              }}
            />
            {arrow && handwritten && (
              <>
                <div className={styles['arrow']}>
                  <MediaImage content={arrow.mediaContent} layout="fixed" height="30" />
                </div>
                <div className={styles['handwritten']}>
                  <MediaImage
                    content={handwritten.mediaContent}
                    layout="fixed"
                    width="130"
                    height="30"
                  />
                </div>
              </>
            )}
          </div>
        )}
      </Grid>
    </Section>
  )
}

export { ValueProps }
