import { Card, CardContent, CardMedia as SunbeamCardMedia, CardActions } from '@achieve/sunbeam'
import { LinkButton, MediaImage, Typography } from 'components/Contentful'
import styles from './CardMedia.module.css'

export function CardMedia({ content, color = 'primary', linkVariant = 'outlined' }) {
  /**
   * This is most likely temporary until a Sunbeam component is ready
   */
  const { cardTitleText, cardText, cardImage, cardLink } = content?.fields || {}
  return (
    <Card className={styles['media-card-container']}>
      {cardImage && (
        <SunbeamCardMedia>
          <MediaImage content={cardImage} className={styles['media-card-image']} />
        </SunbeamCardMedia>
      )}
      <CardContent>
        {cardTitleText && <Typography content={cardTitleText} />}
        {cardText && <Typography content={cardText} />}
      </CardContent>
      {cardLink && (
        <CardActions>
          <LinkButton
            track={{
              list_name: 'Card Media Event',
            }}
            content={cardLink.fields}
            color={color}
            variant={linkVariant}
          />
        </CardActions>
      )}
    </Card>
  )
}
