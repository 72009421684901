import React from 'react'

export function ChildComponentGenerator({ contentTypes, uiComponent, ...props }) {
  if (!uiComponent || !contentTypes) {
    return null
  }
  const COMPONENT = contentTypes[uiComponent]

  if (!COMPONENT) {
    return null
  }

  return React.createElement(COMPONENT, {
    content: { ...props },
  })
}
